import { render, staticRenderFns } from "./otherWayBillCmpt.vue?vue&type=template&id=451a5294&scoped=true"
import script from "./otherWayBillCmpt.vue?vue&type=script&lang=js"
export * from "./otherWayBillCmpt.vue?vue&type=script&lang=js"
import style0 from "./otherWayBillCmpt.vue?vue&type=style&index=0&id=451a5294&prod&lang=css"
import style1 from "./otherWayBillCmpt.vue?vue&type=style&index=1&id=451a5294&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451a5294",
  null
  
)

export default component.exports